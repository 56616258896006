exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-newslist-page-js": () => import("./../../../src/templates/newslist-page.js" /* webpackChunkName: "component---src-templates-newslist-page-js" */),
  "component---src-templates-player-page-js": () => import("./../../../src/templates/player-page.js" /* webpackChunkName: "component---src-templates-player-page-js" */),
  "component---src-templates-players-page-js": () => import("./../../../src/templates/players-page.js" /* webpackChunkName: "component---src-templates-players-page-js" */)
}

